import { ContactCard } from '../../../components/ContactCard';
import { ContactGroup } from '../../../components/ContactGroup';
import { Headline } from '../../../components/Headline';
import { HeroSection } from '../../../components/HeroSection';
import { Paragraph } from '../../../layout/Paragraph';
import { Section } from '../../../components/Section';
import { TeamPhoto } from '../../../components/TeamPhoto';
import { Fragment, ReactElement } from 'react';

const coachesPath = '/teams/u7/coaches';

const U7TeamPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='Bambini'>
      <Paragraph>
        Im Bereich der Bambini werden keine Meisterschaftsrunden ausgetragen. Stattdessen finden vereinzelt Bambini-Spieltage statt. <br />
        Spielberechtigt sind alle Jahrgänge bis 2018.
      </Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Spieltage' />
      <Paragraph>
        Aktuell sind keine Spielpläne vorhanden.
      </Paragraph>
    </Section>
    <Section className='dark'>
      <Headline title='Team' />
      <TeamPhoto src='/teams/u7/Trainerteam.jpg' title='Trainerteam 2023/2024' />
    </Section>
    <Section>
      <Headline title='Trainer und Betreuer' />
      <ContactGroup>
        <ContactCard
          name='Marc Seegis'
          position='Trainer'
          phone='-'
          mail='bambini@sv-heselwangen.de'
          thumbnailUrl={ `${coachesPath}/Marc_Seegis.jpg` }
        />
        <ContactCard
          name='Maria Sarritzu'
          position='Trainer'
          phone='-'
          mail='bambini@sv-heselwangen.de'
          thumbnailUrl={ `${coachesPath}/Maria_Sarritzu.jpg` }
        />
        <ContactCard
          name='Marie Jenter'
          position='Trainer'
          phone='-'
          mail='bambini@sv-heselwangen.de'
          thumbnailUrl={ `${coachesPath}/Marie_Jenter.jpg` }
        />
      </ContactGroup>
    </Section>
  </Fragment>
);

export { U7TeamPage };
