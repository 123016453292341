import { GalleryPreviewMetadata } from '../../domain/GalleryPreviewMetadata';

const getLatestGalleries = (): GalleryPreviewMetadata[] => [
  {
    title: 'AH-Wanderung 2024',
    date: '20.09.2024 - 22.09.2024',
    routePath: 'galerie/ausfluege/2024/ah-wanderung',
    previewPath: `/galleries/trips/2024/ah-wanderung/standard/img_0060.jpg`
  },
  {
    title: 'Gartenschau Wangen 2024',
    date: '07.09.2024',
    routePath: 'galerie/ausfluege/2024/gartenschau-wangen',
    previewPath: `/galleries/trips/2024/gartenschau-wangen/standard/img_0025.jpg`
  },
  {
    title: 'SVH : SGM Ringingen/Killertal (0:3)',
    date: '25.08.2024',
    routePath: 'galerie/spiele/2024/svh-killertal-25-08-24',
    previewPath: `/galleries/matches/2024-2025/svh-killertal-25-08-24/standard/img_0010.jpg`
  }
];

export { getLatestGalleries };
