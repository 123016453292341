import { ContactCard } from '../../../components/ContactCard';
import { ContactGroup } from '../../../components/ContactGroup';
import { FussballdeWidgetContainer } from '../../../components/FussballdeWidgetContainer';
import { FussballdeWidgetNext } from '../../../components/FussballdeWidgetNext';
import { Headline } from '../../../components/Headline';
import { HeroSection } from '../../../components/HeroSection';
import { Paragraph } from '../../../layout/Paragraph';
import { Section } from '../../../components/Section';
import { TeamPhoto } from '../../../components/TeamPhoto';
import { Fragment, ReactElement } from 'react';

const U11TeamPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='E-Jugend'>
      <Paragraph>
        Unsere E-Jugend spielt in der aktuellen Saison in der Qualistaffel 2. <br />
        Spielberechtigt sind die Jahrgänge 2015 und 2014.
      </Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Tabelle und Begegnungen' />
      <FussballdeWidgetContainer>
        <FussballdeWidgetNext elementId='c8c57949-4399-4e69-abb2-89e818101dde' type='team-matches' />
        <FussballdeWidgetNext elementId='fed4af2a-3ba0-4134-a12f-0a38aad2c520' type='table' />
      </FussballdeWidgetContainer>
    </Section>
    <Section className='dark'>
      <Headline title='Kader' />
      <TeamPhoto src='/teams/u11/Mannschaftsfoto.jpg' title='E-Jugend 2021/2022' />
    </Section>
    <Section>
      <Headline title='Trainer und Betreuer' />
      <ContactGroup>
        <ContactCard
          name='Semir Zaiane'
          position='Trainer'
          phone='-'
          mail='e-jugend@sv-heselwangen.de'
          thumbnailUrl='/shared/person-icon.png'
        />
        <ContactCard
          name='Jens Noll'
          position='Trainer'
          phone='-'
          mail='e-jugend@sv-heselwangen.de'
          thumbnailUrl='/shared/person-icon.png'
        />
      </ContactGroup>
    </Section>
  </Fragment>
);

export { U11TeamPage };
