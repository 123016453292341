import { Article } from '../../domain/Article';

const news: Article[] = [
  {
    thumbnail: '/articles/2024/vereinsausflug/vereinsausflug-preview.png',
    image: '',
    title: 'Einladung Vereinsausflug 2024',
    description: 'Einladung zum Vereinsausflug 2024 zur Gartenschau in Wangen im Allgäu. Anmeldeschluss ist der 20. August.',
    date: '29.07.2024',
    routePath: 'einladung-vereinsausflug',
    chapters: [
      {
        header: '',
        paragraphs: [
          {
            type: 'image',
            src: '/articles/2024/vereinsausflug/einladung-vereinsausflug-2024.jpg'
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/2024/bubble-soccer-turnier/bubble-soccer-turnier-2024-canceled-preview.jpg',
    image: '',
    title: 'Bubble-Soccer-Turnier abgesagt',
    description: 'Das Bubble-Soccer-Turnier findet NICHT statt.',
    date: '23.07.2024',
    routePath: 'bubble-soccer-turnier-2024-canceled',
    chapters: [
      {
        header: 'Zu wenig gemeldete Mannschaften',
        paragraphs: [
          {
            type: 'text',
            content: `
              Leider müssen wir euch mitteilen, dass das Bubble-Soccer-Turnier des Fördervereins am kommenden Samstag NICHT stattfindet.
              Grund dafür ist die schlechte Resonanz bei den Anmeldungen. Es haben sich bis zum heutigen Anmeldeschluss leider nur 3 Mannschaften angemeldet.
              Auch eine kurzfristige Lockerung der Teilnahmebedingungen, die zumindest im engeren Bekanntenkreis kommuniziert wurde, konnte keine Verbesserung bewirken.
              Daher sieht der Förderverein sich gezwungen das Turnier abzusagen.
            `
          },
          {
            type: 'text',
            content: `
              Alle angemeldeten Mannschaften, die die Startgebühr bereits überwiesen haben, erhalten diese in den kommenden Tagen zurück.
            `
          },
          {
            type: 'text',
            content: `
              Wir möchten uns im Namen des Fördervereins bei allen, die sich auf das Turnier gefreut haben entschuldigen und hoffen auf euer Verständnis.
              Desweiteren möchten wir um euer Feedback bitten, damit wir wissen, ob denn grundlegend Interesse an einem solchen Turnier besteht und vielleicht einfach nur der Zeitpunkt schlecht gewählt war.
            `
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/2024/suedtiroler-tag/suedtiroler-tag-preview.png',
    image: '',
    title: 'Südtiroler Tag 2024',
    description: 'Der SVH lädt zum Südtiroler Tag nach Heselwangen.',
    date: '15.07.2024',
    routePath: 'suedtiroler-tag-preview',
    chapters: [
      {
        header: '',
        paragraphs: [
          {
            type: 'image',
            src: '/articles/2024/suedtiroler-tag/Flyer-Suedtiroler-Tag-2024.png'
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/2024/bubble-soccer-turnier/bubble-soccer-turnier-2024-preview.jpg',
    image: '',
    title: 'Bubble-Soccer-Turnier 2024',
    description: 'Am 27.07.24 steigt das erste Bubble-Soccer-Turnier in Heselwangen. Jetzt Anmelden!',
    date: '03.06.2024',
    routePath: 'bubble-soccer-turnier-2024-vorschau',
    chapters: [
      {
        header: 'Bubble-Soccer-Premiere in Heselwangen',
        paragraphs: [
          {
            type: 'text',
            content: `
              Mit dem Gerümpel-Turnier im Jahr 2011 hat es angefangen. Darauf folgte viele Jahre das 11-Meter-Turnier.
              Nun ist es an der Zeit für etwas Neues.
              Der Förderverein SV Heselwangen 1906 e.V. veranstaltet in diesem Jahr das erste Bubble-Soccer-Turnier in Heselwangen.
              Wie bereits in den Jahren zuvor handelt es sich um ein reines Freizeit-Turnier für Vereinsmitglieder und Freunde der drei Heselwanger Vereine (Sportverein, Musikverein, Sängerbund).
            `
          }
        ]
      },
      {
        header: 'Teilnahmebedingungen und Anmeldung',
        paragraphs: [
          {
            type: 'text',
            content: `
              Um am Turnier teilzunehmen benötigt ihr ein Team, das aus mindestens 5 Teilnehmern besteht.
              Mindestens 2 Personen aus eurem Team müssen in einem der drei Heselwanger Vereine aktiv sein.
              Alle Teilnehmer müssen mindestens 16 Jahre alt sein.
            `
          },
          {
            type: 'text',
            content: `
              In diesem Jahr beträgt die Startgebühr 25€ pro Team. Die 3 besten Mannschaften erhalten einen von 3 Hauptpreisen, während alle Teilnehmer einen kleinen Trostpreis erhalten.
              Anmelden könnt ihr euch via E-Mail an foerderverein@sv-heselwangen.de unter Angabe des Betreffs "Bubble-Soccer-Turnier 2024".
              Teilt uns außerdem den Namen eures Teams sowie den vollständigen Vor- und Nachnamen eures Team-Captains mit.
              Es können maximal 12 Teams am Turnier teilnehmen.
            `
          },
          {
            type: 'text',
            content: `
              Bitte beachten: Stollenschuhe sind zum Turnier nicht zugelassen. Erlaubt sind ausschließlich Sportschuhe mit glatter Sohle oder Multinocken.
            `
          },
          {
            type: 'text',
            content: `
              Anmeldeschluss ist der 21.07.2024.
            `
          },
          {
            type: 'text',
            content: `
              Die Anmeldung ist nur gültig, wenn die Startgebühr von 25€ bis zum Anmeldeschluss auf das Konto des Fördervereins überwiesen wurde.
              IBAN: DE91641632250420883002
            `
          },
          {
            type: 'text',
            content: `
            (Unbedingt den Namen eures Teams und/oder Team-Captains als Referenz angeben.)
            `
          }
        ]
      },
      {
        header: 'Turnierablauf',
        paragraphs: [
          {
            type: 'text',
            content: `
              Der genaue Turnierplan steht noch nicht fest und ist abhängig von der Anzahl der angemeldeten Mannschaften.
              Soviel können wir aber schonmal verraten:
            `
          },
          {
            type: 'text',
            content: `
              Es wird eine Gruppen- sowie eine KO-Phase geben. Gespielt wird auf einem Spielfeld (ca. 20x30 Meter) mit 2 Toren.
              Es treten immer zwei Mannschaften à 5 Spieler gegeneinander an. Die Spieldauer beträgt ca. 8 Minuten pro Spiel (+- 2 Minuten).
            `
          },
          {
            type: 'text',
            content: `
              Beginn des Turniers ist um 13:00 Uhr auf dem Sportgelände in Heselwangen.
              Sollte es bis zum Turniertag weitere Details oder Änderungen geben, werden wir sie hier veröffentlichen.
            `
          },
          {
            type: 'text',
            content: `
              Wir freuen uns schon riesig und ihr hoffentlich auch!
            `
          }
        ]
      },
      {
        header: 'Impressionen 11-Meter-Turnier 2023',
        paragraphs: [
          {
            type: 'images',
            sources: [
              '/galleries/events/2023/11-meter-turnier/standard/img_0001.jpg',
              '/galleries/events/2023/11-meter-turnier/standard/img_0031.jpg',
              '/galleries/events/2023/11-meter-turnier/standard/img_0064_01.jpg'
            ]
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/2024/werner-luppold-50-jahre/werner-luppold-preview.png',
    image: '',
    title: '50 Jahre in der Schiedsrichtergruppe',
    description: 'Werner Luppold erhält Ehrung zu seinem 80. Geburtstag.',
    date: '07.04.2024',
    routePath: 'werner-luppold-50-jahre',
    chapters: [
      {
        header: 'Ehrung für 50 Jahre in der Schiedsrichtergruppe Zollern-Balingen',
        paragraphs: [
          {
            type: 'text',
            content: `
              Anlässlich seines 80. Geburtstags erhielt Werner Luppold eine Ehrung für 50 Jahre in der Schiedsrichtergruppe Zollernalb.
              Unser 1. Vorstand Hartmut Landbeck überreichte die Ehrenurkunde persönlich.
            `
          }
        ]
      }
    ],
    externalLink: {
      name: 'SRG Balingen-Zollernalb - News',
      preview: '/articles/2024/werner-luppold-50-jahre/srg-news-preview.png',
      destination: 'https://www.srg-zollern-balingen.de/news/'
    }
  },
  {
    thumbnail: '/articles/gv-2024/GV-2024-preview.png',
    image: '',
    title: 'Hauptversammlung 2024',
    description: 'Der SV Heselwangen lädt zur Hauptversammlung 2024 im Schützenhaus Heselwangen.',
    date: '06.03.2024',
    routePath: 'hauptversammlung-2024',
    chapters: [
      {
        header: 'Einladung zur Hauptversammlung 2024',
        paragraphs: [
          {
            type: 'text',
            content: `
              Der SV Heselwangen 1906 e.V. lädt alle Mitglieder sowie Gönner und Freunde des Vereins zur Hauptversammlung am 22.03.2024 ein.
              Aufgrund von baulichen Maßnahmen findet die diesjährige Hauptversammlungen nicht wie gewohnt im Vereinsheim sondern im Schützenhaus in Heselwangen statt.
            `
          },
          {
            type: 'text',
            content: `
              Die Einladung inklusive Tagesordnung könnt ihr nachfolgend als PDF herunterladen.
            `
          },
          {
            type: 'downloads',
            downloads: [
              {
                description: 'Einladung',
                destination: '/articles/gv-2024/GV-2024.pdf',
                preview: '/articles/gv-2024/GV-2024-preview.png'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/2024/mafu-cup/mafu-cup-preview2.jpg',
    image: '',
    title: 'MAFU-Cup 2024 Spielpläne',
    description: `Die Spielpläne stehen euch ab sofort zum Download zur Verfügung.`,
    date: '16.01.2024',
    routePath: 'spielplaene-mafu-cup-2024',
    chapters: [
      {
        header: 'MAFU-Cup 2024 Spielpläne',
        paragraphs: [
          {
            type: 'text',
            content: `
              Die Spielpläne des diesjährigen MAFU-Cups stehen euch hier als PDF-Download zur Verfügung.
              Das Bambini-Spielfest findet am Sonntag, den 04.02.2024 von 16:00 Uhr - 18:00 Uhr statt und wird auf
              zwei Spielfeldern ausgetragen.
            `
          },
          {
            type: 'downloads',
            downloads: [
              {
                description: 'Spielplan B-Jugend',
                preview: '/articles/2024/mafu-cup/B-Junioren.png',
                destination: '/articles/2024/mafu-cup/B-Junioren.pdf'
              },
              {
                description: 'Spielplan E-Jugend',
                preview: '/articles/2024/mafu-cup/E-Junioren.png',
                destination: '/articles/2024/mafu-cup/E-Junioren.pdf'
              },
              {
                description: 'Spielplan C-Jugend',
                preview: '/articles/2024/mafu-cup/C-Junioren.png',
                destination: '/articles/2024/mafu-cup/C-Junioren.pdf'
              },
              {
                description: 'Spielplan D-Jugend',
                preview: '/articles/2024/mafu-cup/D-Junioren.png',
                destination: '/articles/2024/mafu-cup/D-Junioren.pdf'
              },
              {
                description: 'Spielplan F-Jugend',
                preview: '/articles/2024/mafu-cup/F-Junioren.png',
                destination: '/articles/2024/mafu-cup/F-Junioren.pdf'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/2024/mafu-cup/mafu-cup-preview.jpg',
    image: '',
    title: 'MAFU-Cup 2024',
    description: `Die Teilnehmer des diesjährigen MAFU-Cups stehen fest.`,
    date: '16.01.2024',
    routePath: 'vorschau-mafu-cup-2024',
    chapters: [
      {
        header: 'MAFU-Cup 2024',
        paragraphs: [
          {
            type: 'text',
            content: `
              Der MAFU-Cup 2024 findet vom 02.02.2024 bis 04.02.2024 in der Sparkassen-Arena in Balingen statt.
              Insgesamt nehmen 64 Mannschaften am diesjährigen Turnier teil.
            `
          }
        ]
      },
      {
        header: 'Turnierplan',
        paragraphs: [
          {
            type: 'image',
            src: '/articles/2024/mafu-cup/turnierplan.png'
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/nikolausmarkt-2023/nikolausmarkt-2023-preview.png',
    image: '',
    title: 'Nikolausmarkt Heselwangen',
    description: 'Heselwangen lädt am Samstag, den 09. Dezember zum 28. Nikolausmarkt.',
    date: '07.12.2023',
    routePath: 'nikolausmarkt-2023-preview',
    chapters: [
      {
        header: '',
        paragraphs: [
          {
            type: 'image',
            src: '/articles/nikolausmarkt-2023/nikolausmarkt-2023-flyer.jpg'
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/schlachtfest-2023/schlachtfest-2023-preview.png',
    image: '',
    title: 'Schlachtfest 2023',
    description: 'Der Förderverein des SV Heselwangen 1906 e.V. lädt zum Schlachtfest am 05.11.2023.',
    date: '01.11.2023',
    routePath: 'schlachtfest-2023-preview',
    chapters: [
      {
        header: 'Verschiedene Spezialitäten',
        paragraphs: [
          {
            type: 'text',
            content: `
              Auch beim diesjährigen Schlachtfest dürfen wir unseren Gästen im Vereinsheim in Heselwangen verschiedene Spezialitäten anbieten.
            `
          },
          {
            type: 'text',
            content: `
              Beginn ist um 11:00 Uhr im Vereinsheim in Heselwangen.
            `
          },
          {
            type: 'text',
            content: `
              Wir freuen uns!
            `
          },
          {
            type: 'image',
            src: '/articles/schlachtfest-2023/schlachtfest-2023-preview.png'
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/galleries/matches/2023-2024/svh-gauselfingen-01-10-23/standard/img_0042.jpg',
    image: '',
    title: 'SVH : SGM TSV Gauselfingen/TSV Hausen i.K. (5:1)',
    description: 'SVH dominiert hitzige Partie.',
    date: '01.10.2023',
    routePath: 'svh-gauselfingen-01-10-23',
    chapters: [
      {
        header: 'SVH geht früh in Führung',
        paragraphs: [
          {
            type: 'text',
            content: `
              Der SVH geht bereits in der 5. Minute durch Malte Haasis mit 1:0 in Führung.
              In der 22. Spielminute erhöht Max Proschko auf 2:0 ehe die Gäste 1 Minute später den Anschlusstreffer erzielen.
              Davon lässt sich der SVH allerdings nicht beeindrucken und baut die Führung nur wenige Minuten später durch Jason Wagner auf 3:1 aus.
              Neben den vier Toren gibt es im ersten Durchgang nur eine gelbe Karte zu verzeichnen. Das sollte sich noch ändern.
            `
          }
        ]
      },
      {
        header: 'Auf Torreigen folgen Karten',
        paragraphs: [
          {
            type: 'text',
            content: `
              Bereits in Hälfte 1 zeigen beide Mannschaften ein körperbetontes Spiel. Der Unparteiische wählt bis dahin eine eher lockere Linie.
              Das scheint sich nun zu Rächen. Die Partie nimmt immer mehr an Fahrt auf, allerdings nicht spielerisch, sondern in den Zweikämpfen.
              Das Spiel muss immer wieder durch den Schiedsrichter unterbrochen werden.
              Während der SVH mit 3 gelben Karten glimpflich davon kommt, stehen die Gäste zum Ende der Partie mit zwei Mann weniger auf dem Platz.
              Neben den vielen Fouls und Querelen wird aber immernoch Fußball gespielt. Der SVH kann nochmal 2 Tore nachlegen und gewinnt verdient mit 5:1,
              auch dank Schlussmann Nico Ast, der einen Foul-Elfmeter der Gäste erfolgreich abwehren kann.
            `
          }
        ]
      }
    ],
    galleryPreview: {
      title: 'SVH : SGM TSV Gauselfingen/TSV Hausen i.K. (5:1)',
      date: '01.10.2023',
      routePath: '/galerie/spiele/2023/svh-gauselfingen-01-10-23',
      previewPath: `/galleries/matches/2023-2024/svh-gauselfingen-01-10-23/standard/img_0042.jpg`
    }
  },
  {
    thumbnail: '/articles/2023/danksagung-roberto/Roberto.jpg',
    image: '',
    title: 'Grazie Roberto!',
    description: 'Roberto Marzio legt nach 14 Jahren seine Ämter beim SVH nieder und bedankt sich.',
    date: '25.06.2023',
    routePath: 'danksagung-roberto-marzio',
    chapters: [
      {
        header: '14 Jahre Trainer',
        paragraphs: [
          {
            type: 'text',
            content: `
              Vor 14 Jahren hat Roberto Marzio seine Karriere in Heselwangen als Trainer der damaligen Bambini-Mannschaft begonnen.
              Diese Mannschaft hat er bis zur A-Jugend begleitet und seine Schützlinge so buchstäblich aufwachsen sehen.
              So eine Leistung schaffen nur ganz wenige und Roberto gilt unser aller Dank!
              Neben seiner Trainertätigkeit übernahm Roberto in seiner Zeit beim SVH das Amt des Jugendleiters, Abteilungsleiter Fußball und kümmerte sich um unsere Sponsoren.
            `
          }
        ]
      },
      {
        header: 'Ein Dankschreiben von Roberto',
        paragraphs: [
          {
            type: 'text',
            content: `
              Am vergangenen Samstag fand das letzte Spiel unserer A-Jugend in der Saison 2022/2023 statt. Diesen Anlass hat Roberto genutzt, um ein Dankschreiben zu verfassen.
            `
          },
          {
            type: 'text',
            content: `
              "Ich möchte mich auf diesem Weg noch einmal bei allen für die 14 Jahre bedanken.
              Ich habe in diesen Jahren beim SVH tolle, sympathische und engagierte Menschen kennengelernt.
              Damit meine ich nicht nur die Jungs auf und neben dem Platz sondern genauso die Zuschauer, Eltern, Jugendtrainer, den Vorstand und auch die vielen anderen Vereinsmitglieder.
              Besonders bedanken möchte ich mich bei meinem Sohn Alessandro. Ohne ihn hätte ich die Jungs und den SVH nicht kennengelernt.
              Ich erinnere mich noch an den ersten Tag, da sagte Alessandro: 'Papà können wir am Sportplatz trainieren gehen. Ich möchte da Fußball spielen.'
              Und jetzt sind schon 14 Jahre vergangen.
              Niemals hätte ich zu Beginn gedacht, dass ich 14 Jahre als Trainer von den Bambinis bis zu den A-Junioren, vier Jahre als Jugendleiter, zwei Jahre als Abteilungsleiter der Aktiven in Heselwangen verbringe.
              Aber ich und meine Familie haben uns einfach immer wohl und gut aufgenommmen gefühlt. Ich habe mich immer in dieser Zeit am richtigen Platz gefühlt, weil ich wußte was ich am SVH habe.
              Vielen Dank nochmal, dass mir der SVH die Chance gegeben hat diese tolle Truppe zu trainieren. Vielen Dank an die Jungs für diese 14 tollen Jahre.
              Es gibt eine Menge Momente, Szenen, Feiern, Ausflüge, Sprüche und Erfolge an die ich mich ganz sicher noch in 30 Jahren erinnern werde.
              Ich bin mir sehr sicher, dass ich auch noch in den nächsten Jahren, wenn ich meine Spieler im Aktiven Bereich sehe, mitfiebern werde.
              Ich drücke jedem Einzelnen und dem gesamten Verein die Daumen. Meine Jungs und der SVH werden ein Stück von mir bleiben!
              Liebe Grüße Roberto"
            `
          },
          {
            type: 'text',
            content: `
              Roberto wird dem SVH natürlich weiterhin erhalten bleiben und sicher in Zukunft noch einige male die Grillzange schwingen.
              Lieber Roberto, im Namen des SVH: Mille Grazie!
            `
          },
          {
            type: 'images',
            sources: [
              '/articles/2023/danksagung-roberto/Mannschaftsfoto-u17.jpg',
              '/articles/2023/danksagung-roberto/Roberto.jpg'
            ]
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/2023/11-meter-turnier/11-meter-turnier-2023-preview.jpg',
    image: '',
    title: '11-Meter-Turnier 2023',
    description: 'Die Mannschaften stehen fest. (Aktualisiert am: 29.05.2023)',
    date: '06.05.2023',
    routePath: '11-meter-turnier-2023-vorschau',
    chapters: [
      {
        header: 'Gemeldete Mannschaften',
        paragraphs: [
          {
            type: 'image',
            src: '/articles/2023/11-meter-turnier/gemeldete-mannschaften-final.png'
          }
        ]
      },
      {
        header: 'Es ist wieder soweit',
        paragraphs: [
          {
            type: 'text',
            content: `
              Am 08. Juni 2023 (Fronleichnam) findet bereits zum 7. mal unser 11-Meter-Turnier in Heselwangen statt.
              Wie bereits in den Jahren zuvor handelt es sich um ein reines Freizeit-Turnier für Vereinsmitglieder und Freunde der drei Heselwanger Vereine (Sportverein, Musikverein, Sängerbund).
              Die Organisation übernimmt der Förderverein SV Heselwangen 1906 e.V. Die Bewirtung am Turniertag übernehmen die Waschbärboyche.
            `
          }
        ]
      },
      {
        header: 'Teilnahmebedingungen und Anmeldung',
        paragraphs: [
          {
            type: 'text',
            content: `
              Um am Turnier teilzunehmen benötigt ihr ein Team, das aus mindestens 5 Teilnehmern besteht.
              Mindestens 3 Personen aus eurem Team müssen in einem der drei Heselwanger Vereine aktiv sein.
              Alle Teilnehmer müssen mindestens 16 Jahre alt sein.
            `
          },
          {
            type: 'text',
            content: `
              Es gibt keine Startgebühr, allerdings muss jedes Team einen "geheimen" Preis (Warenwert ca. 25€) am Turniertag bereitstellen.
              Anmelden könnt ihr euch via E-Mail an foerderverein@sv-heselwangen.de unter Angabe des Betreffs "11-Meter-Turnier 2023".
              Teilt uns außerdem den Namen eures Teams sowie den vollständigen Vor- und Nachnamen eures Team-Captains mit.
              Es können maximal 12 Teams am Turnier teilnehmen.
              Anmeldeschluss ist der 25.05.2023 !!!
            `
          },
          {
            type: 'text',
            content: `
              Desweiteren benötigen wir für die Verpflegung jede Menge Kuchen. Daher würden wir uns sehr freuen, wenn ihr einen Kuchen (Muffins o.ä. gehen auch) zum Turniertag mitbringt.
              Teilt uns dies bitte ebenfalls per E-Mail mit, damit wir besser planen können.
            `
          }
        ]
      },
      {
        header: 'Turnierablauf',
        paragraphs: [
          {
            type: 'text',
            content: `
              Der genaue Turnierplan steht noch nicht fest und ist abhängig von der Anzahl der angemeldeten Mannschaften.
              Soviel können wir aber schonmal verraten: 
            `
          },
          {
            type: 'text',
            content: `
              Es wird wie in den Jahren zuvor auf zwei Tore gespielt, d.h. es finden immer zwei Spiele gleichzeitig statt.
              Jedes Team muss pro Begegnung 5 Schützen stellen. Torhüter dürfen ebenfalls schießen.
              Doppelt schießen darf ein Schütze nur, wenn es in die Verlängerung geht.
              Die Schiedsrichter werden immer von den Teams der vorherigen Partie gestellt.
            `
          },
          {
            type: 'text',
            content: `
              Beginn des Turniers ist um 10:00 Uhr auf dem Sportgelände in Heselwangen.
              Sollte es bis zum Turniertag weitere Details oder Änderungen geben, werden wir sie hier veröffentlichen.
            `
          },
          {
            type: 'text',
            content: `
              Wir freuen uns schon riesig und ihr hoffentlich auch!
            `
          }
        ]
      },
      {
        header: 'Impressionen 2022',
        paragraphs: [
          {
            type: 'images',
            sources: [
              '/galleries/events/2022/elfmeter-turnier-2022/standard/img_0043.jpg',
              '/galleries/events/2022/elfmeter-turnier-2022/standard/img_0006.jpg',
              '/galleries/events/2022/elfmeter-turnier-2022/standard/img_0112.jpg'
            ]
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/galleries/matches/2022-2023/svh-bittelbronn-23-04-23/standard/img_0012.jpg',
    image: '',
    title: 'SVH : SG Weildorf/Bittelbronn (0:5)',
    description: 'SVH verschläft erste Halbzeit.',
    date: '23.04.2023',
    routePath: 'svh-bittelbronn-23-04-2023',
    chapters: [
      {
        header: 'SVH gerät früh in Rückstand',
        paragraphs: [
          {
            type: 'text',
            content: `
              Gerade einmal 1 Minute dauert es, bis die Gäste mit 0:1 in Führung gehen.
              Der Treffer zum 0:2 fällt kurz darauf in der 5. Spielminute.
              Unsere Jungs scheinen mit dem Kopf woanders zu sein, aber nicht auf dem Platz.
              So fällt in der 28. Spielminute das 0:3. Torhüter Nico Ast ist bis dahin machtlos, kann sogar einige gefährliche Flanken der Gäste in den Straufraum entschärfen.
              Beim darauffolgenden 0:4 sieht er allerdings unglücklich aus, als er beim Versuch zu klären am Ball vorbeischlägt.
              Die Gesamte Heselwanger Mannschaft findet nicht ins Spiel, leistet sich zu viele individuelle Fehler.
            `
          }
        ]
      },
      {
        header: 'Heselwangen gibt nicht auf',
        paragraphs: [
          {
            type: 'text',
            content: `
              Als unsere Jungs nach der Halbzeitpause aus der Kabine kommen wirken sie deutlich wacher.
              Der SVH spielt nun deutlich aggressiver gegen den Ball und schafft es sich direkt zu Beginn der zweiten Hälfte mehrere Torchancen zu erspielen,
              scheitert aber unter anderem am Keeper der Gäste, der bis dahin wenig zu tun hatte.
              Kurz darauf erzielen die Gäste aus Weildorf/Bittelbronn nach einem Konter das 0:5. Der SVH wehrt sich weiterhin und gibt nicht auf, schafft es aber letzten Endes
              nicht die Kugel im gegnerischen Tor unterzubringen.
            `
          },
          {
            type: 'text',
            content: `
              Bereits vor diesem Topspiel dürfte klar gewesen sein: Eine Niederlage gegen den mit Abstand vorne liegenden Tabellenführer wäre in Ordnung.
              Aber eine solch desolate Leistung wie in Halbzeit 1 liegt weit unterhalb des Niveaus unserer Mannschaft, die zuletzt eine starke Form bewiesen hat.
              Nun gilt es den Staub abzuschütteln und den Fokus auf die nächsten Partien zu lenken.
            `
          }
        ]
      }
    ],
    galleryPreview: {
      title: 'SVH : SG Weildorf/Bittelbronn (0:5)',
      date: '02.04.2023',
      routePath: '/galerie/spiele/2022/svh-bittelbronn-23-04-23',
      previewPath: `/galleries/matches/2022-2023/svh-bittelbronn-23-04-23/standard/img_0012.jpg`
    }
  },
  {
    thumbnail: '/galleries/matches/2022-2023/svh-salmendingen-02-04-23/standard/img_0015.jpg',
    image: '',
    title: 'SVH : FC Stetten/Salmendingen (6:1)',
    description: 'Dolezych ballert SVH zum Sieg.',
    date: '02.04.2023',
    routePath: 'svh-salmendingen-02-04-2023',
    chapters: [
      {
        header: 'SVH geht früh in Führung',
        paragraphs: [
          {
            type: 'text',
            content: `
              Bereits in der 5. Spielminute geht der SVH mit 1:0 in Führung.
              Trainer Giuseppe Crachhiolo setzt den Ball mit viel Druck an den Pfosten, Patrick Dolezych verwandelt den Nachschuss und leitet damit eine
              furiose Tor-Serie ein.
            `
          }
        ]
      },
      {
        header: 'Dolezych nicht zu stoppen',
        paragraphs: [
          {
            type: 'text',
            content: `
              Nur 7 Minuten später gelingt unserer Nr. 11 der nächste Treffer zum 2:0. Danach wird das Spiel etwas zerfahrener.
              Nun kommen auch die Gäste zu ein paar eher ungefährlichen Chancen.
              Kurz vor dem Pausenpfiff erhöht Giuseppe Crachhiolo auf 3:0.
            `
          },
          {
            type: 'text',
            content: `
              Nach dem Wiederanpfiff dominiert der SVH die Partie. Es folgen Treffer 4, 5 und 6 durch Patrick Dolezych.
              Der Ehrentreffer der Gäste in der 60. Minute ist unnötig, aber sei ihnen gegönnt.
              Den ein oder anderen Akzent nach vorne können sie trotz der Überlegenheit unserer Mannschaft in Halbzeit zwei noch setzen, scheitern aber entweder an
              unserer Nummer 1 oder sich selbst.
            `
          }
        ]
      }
    ],
    galleryPreview: {
      title: 'SVH : FC Stetten/Salmendingen (6:1)',
      date: '02.04.2023',
      routePath: '/galerie/spiele/2022/svh-salmendingen-02-04-23',
      previewPath: `/galleries/matches/2022-2023/svh-salmendingen-02-04-23/standard/img_0015.jpg`
    }
  },
  {
    thumbnail: '/articles/spieltag_18-20_22-23/svh-salmendingen-article-preview.png',
    image: '',
    title: 'Doppelspieltag in Frommern',
    description: 'Spiele beider Herren-Mannschaften nach Frommern verlegt.',
    date: '01.04.2023',
    routePath: 'verlegung-svh-svh2-nach-frommern',
    chapters: [
      {
        header: 'Spiele nach Frommern verlegt',
        paragraphs: [
          {
            type: 'text',
            content: `
              Auch an diesem Spieltag müssen die beiden Spiele unserer Herren-Mannschaften auf einen Kunstrasenplatz verlegt werden.
              Beide Spiele finden an am morgigen Sonntag in Frommern statt.
              Los geht es um 13:30 Uhr mit der 2. Mannschaft gegen die SGM TSV Obernheim II / TSV Nusplingen II.
              Im Anschluss trifft unsere 1. Mannschaft auf den FC Stetten/Salmendingen.
            `
          },
          {
            type: 'text',
            content: `
              Der Sportplatz in Heselwangen ist aufgrund der anhaltenden Niederschläge nicht bespielbar.
            `
          },
          {
            type: 'images',
            sources: [
              '/articles/spieltag_18-20_22-23/svh-nusplingen-preview.jpg',
              '/articles/spieltag_18-20_22-23/svh-salmendingen-preview.jpg'
            ]
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/spieltag16_22-23/vorschau-spieltag16.png',
    image: '',
    title: 'Doppelspieltag in Geislingen',
    description: '2. Mannschaft spielt ebenfalls in Geislingen.',
    date: '24.03.2023',
    routePath: 'spielstaetten-verlegung-svh2',
    chapters: [
      {
        header: 'Spiel nach Geislingen verlegt',
        paragraphs: [
          {
            type: 'text',
            content: `
              Die Witterungsverhältnisse der vergangenen Tage haben dem Sportplatz in Heselwangen extrem zugesetzt.
              Um den Platz zu schonen wurde das Spiel der 2. Mannschaft gegen die SGM Roßwangen/Endingen II auf den Kunstrasenplatz
              nach Geislingen verlegt. Spielbeginn ist um 13:00 Uhr.
            `
          },
          {
            type: 'text',
            content: `
              Im Anschluss tritt unsere 1. Mannschaft, ebenfalls auf dem Kunstrasen, gegen die Hausherren aus Geislingen an.
            `
          },
          {
            type: 'images',
            sources: [
              '/articles/spieltag16_22-23/svh2-rosswangen-vorschau.jpeg',
              '/articles/spieltag16_22-23/geislingen-svh-vorschau.jpeg'
            ]
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/gv-2023/GV-2023-preview.png',
    image: '',
    title: 'Hauptversammlung 2023',
    description: 'Der SV Heselwangen lädt zur Hauptversammlung 2023 im Vereinsheim.',
    date: '28.02.2023',
    routePath: 'hauptversammlung-2023',
    chapters: [
      {
        header: 'Einladung zur Hauptversammlung 2023',
        paragraphs: [
          {
            type: 'text',
            content: `
              Der SV Heselwangen 1906 e.V. lädt alle Mitglieder sowie Gönner und Freunde des Vereins zur Hauptversammlung am 10.03.2023 ins Vereinsheim in Heselwangen ein.
              Da in diesem Jahr Neuwahlen stattfinden hoffen wir auf ein zahlreiches erscheinen unserer Mitglieder.
              Wie immer steht euch unser Bewirtungsteam mit Speiß und Trank zur Verfügung.
            `
          },
          {
            type: 'text',
            content: `
              Die Einladung könnt ihr nachfolgend als PDF herunterladen.
            `
          },
          {
            type: 'downloads',
            downloads: [
              {
                description: 'Einladung',
                destination: '/articles/gv-2023/GV-2023.pdf',
                preview: '/articles/gv-2023/GV-2023-preview.png'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/mafu-cup-2023/mafu-cup-preview.png',
    image: '',
    title: 'Spielpläne MAFU-Cup 2023',
    description: `Die Begegnungen des MAFU-Cups 2023 stehen fest.`,
    date: '12.02.2023',
    routePath: 'spielplaene-mafu-cup-2023',
    chapters: [
      {
        header: 'MAFU-Cup 2023',
        paragraphs: [
          {
            type: 'image',
            src: '/articles/mafu-cup-2023/mafu-cup-preview.jpg'
          },
          {
            type: 'text',
            content: `
              Kommende Woche findet vom 17.02.2023 bis 19.02.2023 wieder unser beliebter MAFU-Cup in der Sparkassen-Arena in Balingen statt.
              Insgesamt 77 Jugend-Teams nehmen an diesem Mega-Event teil.
            `
          }
        ]
      },
      {
        header: 'Spielpläne und Live Ergebnisse',
        paragraphs: [
          {
            type: 'text',
            content: `
              Unsere Spielpläne stehen euch als PDF-Download zur Verfügung.
            `
          },
          {
            type: 'downloads',
            downloads: [
              {
                description: 'Spielplan A-Jugend',
                preview: '/articles/mafu-cup-2023/A-Junioren-preview.png',
                destination: '/articles/mafu-cup-2023/A-Junioren.pdf'
              },
              {
                description: 'Spielplan B-Jugend',
                preview: '/articles/mafu-cup-2023/B-Junioren-preview.png',
                destination: '/articles/mafu-cup-2023/B-Junioren.pdf'
              },
              {
                description: 'Spielplan C-Jugend',
                preview: '/articles/mafu-cup-2023/C-Junioren-preview.png',
                destination: '/articles/mafu-cup-2023/C-Junioren.pdf'
              },
              {
                description: 'Spielplan D-Jugend',
                preview: '/articles/mafu-cup-2023/D-Junioren-preview.png',
                destination: '/articles/mafu-cup-2023/D-Junioren.pdf'
              },
              {
                description: 'Spielplan E-Jugend',
                preview: '/articles/mafu-cup-2023/E-Junioren-preview.png',
                destination: '/articles/mafu-cup-2023/E-Junioren.pdf'
              },
              {
                description: 'Spielplan F-Jugend',
                preview: '/articles/mafu-cup-2023/F-Junioren-preview.png',
                destination: '/articles/mafu-cup-2023/F-Junioren.pdf'
              },
              {
                description: 'Spielplan Bambini-Spielfest',
                preview: '/articles/mafu-cup-2023/Bambinispielfest-preview.png',
                destination: '/articles/mafu-cup-2023/Bambinispielfest.pdf'
              }
            ]
          },
          {
            type: 'text',
            content: `Auf meinturnierplan.de könnt ihr die Ergebnisse der einzelnen Spiele live mitverfolgen.`
          },
          {
            type: 'externalLinks',
            links: [
              {
                name: 'Live Ergebnisse A-Jugend',
                preview: '/articles/mafu-cup-2023/liveticker.png',
                destination: 'https://www.meinturnierplan.de/showit.php?id=1667897391'
              },
              {
                name: 'Live Ergebnisse B-Jugend',
                preview: '/articles/mafu-cup-2023/liveticker.png',
                destination: 'https://www.meinturnierplan.de/showit.php?id=1667891913'
              },
              {
                name: 'Live Ergebnisse C-Jugend',
                preview: '/articles/mafu-cup-2023/liveticker.png',
                destination: 'https://www.meinturnierplan.de/showit.php?id=1667897699'
              },
              {
                name: 'Live Ergebnisse D-Jugend',
                preview: '/articles/mafu-cup-2023/liveticker.png',
                destination: 'https://www.meinturnierplan.de/showit.php?id=1667894405'
              },
              {
                name: 'Live Ergebnisse E-Jugend',
                preview: '/articles/mafu-cup-2023/liveticker.png',
                destination: 'https://www.meinturnierplan.de/showit.php?id=1667890306'
              },
              {
                name: 'Live Ergebnisse F-Jugend',
                preview: '/articles/mafu-cup-2023/liveticker.png',
                destination: 'https://www.meinturnierplan.de/showit.php?id=1667898123'
              },
              {
                name: 'Live Ergebnisse Bambini',
                preview: '/articles/mafu-cup-2023/liveticker.png',
                destination: 'https://www.meinturnierplan.de/showit.php?id=1667898477'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/hallenturniere-22-23/hallenturniere-22-23-preview.png',
    image: '',
    title: 'Hallenturniere der Herren',
    description: `Aktive starten in die Hallenrunde.`,
    date: '27.12.2022',
    routePath: 'hallenturniere-22-23',
    chapters: [
      {
        header: 'Insgesamt 3 Hallenturniere stehen auf dem Plan',
        paragraphs: [
          {
            type: 'text',
            content: `
              Eine kleine Auswahl an Spielern unserer 1. und 2. Mannschaft nehmen während der Winterpause an insgesamt 3 Hallenturnieren teil.
              Los geht es am Mittwoch, den 28.12.2022 beim 26. Indoor Cup der TSG Balingen in der Sparkassen-Arena. Beginn der Vorrunde ist um 17:00 Uhr.
            `
          },
          {
            type: 'text',
            content: `
              Weitere Vorrunden-Termine sind der 07.01.2023 in Burladingen sowie der 12.01.2023 in Empfingen.
            `
          }
        ]
      }
    ],
    externalLink: {
      name: 'Spielplan Indoor Cup',
      preview: '/articles/hallenturniere-22-23/indoor-cup-2022-preview.png',
      destination: 'https://www.tsg-fussball.de/wp-content/uploads/2022/12/Spielplan-26er-Sparkassen-Indoor-Cup-2022.pdf'
    }
  },
  {
    thumbnail: '/articles/schlachtfest-2022/schlachtfest-2022-preview.jpg',
    image: '',
    title: 'Schlachtfest 2022',
    description: 'Der Förderverein des SV Heselwangen 1906 e.V. lädt zum Schlachtfest am 30.10.2022.',
    date: '23.10.2022',
    routePath: 'schlachtfest-2022-preview',
    chapters: [
      {
        header: 'Verschiedene Spezialitäten',
        paragraphs: [
          {
            type: 'text',
            content: `
              Auch beim diesjährigen Schlachtfest dürfen wir unseren Gästen im Vereinsheim in Heselwangen verschiedene Spezialitäten anbieten.
            `
          },
          {
            type: 'text',
            content: `
              Wir freuen uns!
            `
          },
          {
            type: 'image',
            src: '/articles/schlachtfest-2022/schlachtfest-2022-preview.jpg'
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/galleries/matches/2022-2023/svh-binsdorf-09-10-22/standard/img_0033.jpg',
    image: '',
    title: 'SVH : SpVgg (1:2)',
    description: 'SVH unterliegt SpVgg Binsdorf mit 1:2.',
    date: '09.10.2022',
    routePath: 'svh-binsdorf-09-10-22',
    chapters: [
      {
        header: 'SVH lässt zu viele Chancen liegen',
        paragraphs: [
          {
            type: 'text',
            content: `
              Unsere 1. Mannschaft verliert ihr heutiges Heimspiel gegen die SpVgg aus Binsdorf knapp mit 2:1.
              Trotz der frühen Führung der Gäste hat der SVH in Halbzeit 1 deutlich mehr Chancen, kann diese aber nicht nutzen und geht mit einem 0:1 Rückstand in die Pause.
              Dass die Gäste aus Binsdorf zu dieser Zeit noch keinen Gegentreffer kassiert haben ist einzig dem gegnerischen Keeper zu verdanken, der bis dahin eine starke Partie zeigt.
            `
          },
          {
            type: 'text',
            content: `
              Nur wenige Minuten nach Wiederanpfiff gelingt den Gästen erneut ein Treffer, der allerdings aufgrund einer anzunehmenden Abseitsposition des Torschützen nicht hätte zählen dürfen.
              Unbeeindruckt davon macht der SVH weiter und erzielt nach kurzer Zeit den Anschlusstreffer. Im weiteren Verlauf der Partie werden die klaren Torchancen weniger und die Zweikämpfe härter.
              Der Unparteiische bekommt nun immer mehr zu tun. In der Nachspielzeit wird der Heselwanger Stürmer Patrick Dolezych unmittelbar vor dem gegnerischen Strafraum gefoult.
              Der verdiente Freistoß bleibt allerdings aus - die Partie endet mit 1:2.
            `
          }
        ]
      }
    ],
    galleryPreview: {
      title: 'SVH : SpVgg Binsdorf (1:2)',
      date: '25.09.2022',
      routePath: '/galerie/spiele/2022/svh-binsdorf-09-10-22',
      previewPath: `/galleries/matches/2022-2023/svh-binsdorf-09-10-22/standard/img_0033.jpg`
    }
  },
  {
    thumbnail: '/galleries/matches/2022-2023/svh-rosenfeld-25-09-22/standard/img_0005.jpg',
    image: '',
    title: 'SVH : SGM SV Rosenfeld / TSV Brittheim (1:1)',
    description: 'SVH holt nur ein Remis gegen die SGM SV Rosenfeld / TSV Brittheim.',
    date: '25.09.2022',
    routePath: 'svh-rosenfeld-25-09-22',
    chapters: [
      {
        header: 'SVH beginnt stark',
        paragraphs: [
          {
            type: 'text',
            content: `
              Unsere 1. Mannschaft schafft im heutigen Heimspiel gegen die SGM aus Rosenfeld / Brittheim nur ein Remis und rutscht in der Tabelle um einen Platz nach unten.
              In Halbzeit 1 lassen unsere Jungs hinten nur sehr wenig zu und machen nach vorne Druck. Bereits nach 15 Minuten erzielt Patrick Dolezych das 1:0 für den SVH.
              Etwa 10 Minuten später können die Gäste durch einen gut platzierten Distanzschuss den Anschluss wiederherstellen.
            `
          }
        ]
      },
      {
        header: 'Zweite hälfte deutlich ausgeglichener',
        paragraphs: [
          {
            type: 'text',
            content: `
              Nach dem Seitenwechsel wird die Partie zunehmen Körperbetonter und die Gäste kommen immer besser in die Partie.
              Dennoch kommen beide Mannschaften zu wenig zwingenden Torchancen. Am Ende bleibt es bei einem Unentschieden, mit dem sich der SVH zufrieden geben muss.
            `
          }
        ]
      }
    ],
    galleryPreview: {
      title: 'SVH : SGM SV Rosenfeld / TSV Brittheim (1:1)',
      date: '25.09.2022',
      routePath: '/galerie/spiele/2022/svh-rosenfeld-25-09-22',
      previewPath: `/galleries/matches/2022-2023/svh-rosenfeld-25-09-22/standard/img_0005.jpg`
    }
  },
  {
    thumbnail: '/galleries/matches/2022-2023/svh-ringingen-04-09-22/standard/img_0029.jpg',
    image: '',
    title: 'SVH : SV Ringingen (5:2)',
    description: '1. Mannschaft holt nächsten Dreier.',
    date: '04.09.2022',
    routePath: 'svh-ringingen-04-09-2022',
    chapters: [
      {
        header: 'Heselwanger Offensive dreht auf',
        paragraphs: [
          {
            type: 'text',
            content: `
              Beim heutigen 7-Tore-Festival zeigt sich die Heselwanger Offensive von ihrer besten Seite.
              Bereits in der 1. Spielminute gehen die Hausherren mit 1:0 in Führung.
              Der SV Ringingen findet zunächst überhaupt nicht ins Spiel und so steht es nach 25 Minuten bereits 3:0 für Heselwangen.
              Erst nach einer knappen halben Stunde schaffen die Gäste nach einem Standard den Anschlusstreffer zum 3:1 Pausenstand.
            `
          },
          {
            type: 'text',
            content: `
              In Halbzeit zwei wird die Partie zwar etwas ausgeglichener, doch Heselwangen hält den Druck aufrecht und kommt immer wieder zu brandgefährlichen Chancen, die der SVH
              nicht nutzen kann, was nicht zuletzt an der guten Leistung des gegnerischen Keepers liegt. Nach dem zwischenzeitlichen 4:1 schaffen die Gäste erneut einen Anschlusstreffer zum 4:2.
              In der Schlussphase macht der SVH den Deckel drauf und gewinnt hochverdient mit 5:2.
            `
          }
        ]
      }
    ],
    galleryPreview: {
      title: 'SVH : SGM Ringingen (5:2)',
      date: '04.09.2022',
      routePath: '/galerie/spiele/2022/svh-ringingen-04-09-22',
      previewPath: `/galleries/matches/2022-2023/svh-ringingen-04-09-22/standard/img_0029.jpg`
    }
  },
  {
    thumbnail: '/galleries/matches/2022-2023/svh-heuberg-01-09-22/standard/img_0010.jpg',
    image: '',
    title: 'SVH : SGM Heuberg (2:1)',
    description: '1. Mannschaft gewinnt erstes Heimspiel der Saison.',
    date: '02.09.2022',
    routePath: 'svh-heuberg-01-09-2022',
    chapters: [
      {
        header: 'Viele Strafstöße und Abseits-Entscheidungen',
        paragraphs: [
          {
            type: 'text',
            content: `
              Das gestrige Heimspiel gegen die SGM Heuberg (SV Schwenningen / TSV Stetten a.k.M. / SV Frohnstetten II) war an Spannung kaum zu überbieten.
              Spielerisch war unsere 1. Mannschaft den Gästen über weite Strecken überlegen, doch nach etwa 30 Minuten entscheidet der Schiedsrichter auf Strafstoß und der SVH droht in Rückstand zu geraten.
              Der Heselwanger Keeper Nico Ast kann den Strafstoß jedoch abwehren und es bleibt vorerst beim 0:0.
              Nur wenige Minuten später bekommt der SVH einen Strafstoß zugesprochen. Lennart Kelm tritt an und verwandelt souverän zum 1:0 Pausenstand.
            `
          },
          {
            type: 'text',
            content: `
              In Halbzeit zwei macht der SVH zunächst weiter wie bisher und gibt den Ton der Partie an. Zwar versuchen die Gäste immer wieder über Steilpässe in den Heselwanger Strafraum vorzudringen, werden in den meisten Fällen
              aber vom Unparteiischen zurückgepfiffen. Mitte der zweiten Hälfte bekommt die SGM erneut einen Strafstoß und schafft dadurch den Anschlusstreffer.
            `
          }
        ]
      },
      {
        header: 'Spiel wird zur Zitterpartie',
        paragraphs: [
          {
            type: 'text',
            content: `
              Nach dem Anschlusstreffer werden die Heuberger mutiger und erarbeiten sich die ein oder andere Torchance, die sie allerdings nicht nutzen können. Auch der SVH spielt sich immerwieder in den gegnerischen Strafraum, agiert
              vor dem Tor der Gäste aber zu hektisch und so bleibt es am Ende bei einem knappen aber verdienten Sieg für Heselwangen.
            `
          }
        ]
      }
    ],
    galleryPreview: {
      title: 'SVH : SGM Heuberg (2:1)',
      date: '01.09.2022',
      routePath: '/galerie/spiele/2022/svh-heuberg-01-09-22',
      previewPath: `/galleries/matches/2022-2023/svh-heuberg-01-09-22/standard/img_0010.jpg`
    }
  },
  {
    thumbnail: '/articles/svh-rwebingen-15-08-22/timg_0002.jpg',
    image: '',
    title: 'SVH verliert erstes Pflichtspiel',
    description: '1. Mannschaft scheitert in Runde 1 des Bezirkspokals.',
    date: '18.08.2022',
    routePath: 'svh-rwebingen-15-08-2022',
    chapters: [
      {
        header: 'Erstes Pflichtspiel der Saison 2022/2023',
        paragraphs: [
          {
            type: 'text',
            content: `
              In der 1. Runde des WFV Bezirkspokals empfingen unsere Jungs am gestrigen Sonntag den FV Rot-Weiß Ebingen.
              2015 standen sich die beiden Mannschaften in der Relegation gegenüber, als der SVH um den Aufstieg in die Kreisliga A spielte und der
              FV Rot-Weiß Ebingen einen Abstieg aus jener gerade so vermeiden konnte.
              Inzwischen sind die Gäste in der Bezirksliga vertreten und gingen daher als klarer Favorit in die Partie.
            `
          },
          {
            type: 'images',
            sources: [
              '/articles/svh-rwebingen-15-08-22/img_0001.jpg',
              '/articles/svh-rwebingen-15-08-22/img_0003.jpg'
            ]
          }
        ]
      },
      {
        header: 'Kein Klassenunterschied zu sehen',
        paragraphs: [
          {
            type: 'text',
            content: `
              In der ersten Hälfte der Partie steht es lange 0:0, die Partie ist ausgeglichen.
              Unmittelbar vor dem Halbzeitpfiff erzielen die Gäste das 1:0 nach einem Eckball.
              Nach der Pause kommt der SVH entschlossen aus der Kabine zurück und schafft früh den Anschluss, ehe die Ebinger per Foul-Elfmeter wieder in Führung gehen.
            `
          },
          {
            type: 'images',
            sources: [
              '/articles/svh-rwebingen-15-08-22/img_0002.jpg'
            ]
          },
          {
            type: 'text',
            content: `
            Zwischendrin kommen beide Mannschaften immerwieder zu Chancen, die sie jedoch nicht nutzen können.
            Erst kurz vor dem Abpfiff gelingt den Gästen ein weiteres Tor zum Entstand von 1:3.
            So deutlich wie das Ergebnis war der Leistungsunterschied nicht. Ein Klassenunterschied war nicht zu erkennen.
            `
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/fanshop-new/fanshop-preview.png',
    image: '',
    title: 'SVH Fanartikel',
    description: `Die neue SVH Fan-Kollektion ist da.
                  Jetzt Fanartikel bestellen!`,
    date: '29.07.2022',
    routePath: 'svh-fanshop',
    chapters: [
      {
        header: 'SVH Fanartikel ab sofort verfügbar',
        paragraphs: [
          {
            type: 'text',
            content: `
              Ab sofort könnt ihr online über den Vereinsfieber-Shop unsere offiziellen SVH Fanartikel erwerben.
            `
          },
          {
            type: 'text',
            content: `
              Die Kollektion wurde von "meinvereinsfieber.de" designed und wird ausschließlich über diesen Shop vertrieben.
            `
          }
        ]
      }
    ],
    externalLink: {
      name: 'Zum Fanshop',
      preview: '/articles/fanshop-new/fanshop-preview.png',
      destination: 'https://meinvereinsfieber.de/collections/sv-heselwangen-1906-e-v'
    }
  },
  {
    thumbnail: '/articles/suedtiroler-tag-2022/suedtiroler-tag-2022-preview.png',
    image: '',
    title: 'Südtiroler Tag 2022',
    description: 'Der diesjährige Südtiroler Tag findet wieder auf dem Festplatz statt.',
    date: '12.07.2022',
    routePath: 'suedtiroler-tag-2022-preview',
    chapters: [
      {
        header: 'Südtiroler Tag zum "na hocka"',
        paragraphs: [
          {
            type: 'text',
            content: `
              Nachdem wir in den letzten 2 Jahren nur eine kleine Auswahl an Südtiroler Spezialitäten zum Mitnehmen anbieten konnten, dürfen wir mit Freude verkünden, dass
              unser beliebter Südtiroler Tag wieder wie gewohnt auf dem Festplatz in Heselwangen stattfinden kann.
            `
          }
        ]
      },
      {
        header: 'Verschiedene Spezialitäten',
        paragraphs: [
          {
            type: 'text',
            content: `
              Wie in den Jahren zuvor dürfen wir unseren Gästen wieder ein breites Angebot an Südtiroler Spezialitäten, wie Speck, Käse, Kaminwurz, Vinschgauer, Knödel u.v.m anbieten.
              In unserer Weinlaube haben wir verschiedene Weine, Aperitifs und Spritzgetränke im Angebot.
            `
          },
          {
            type: 'text',
            content: `
              Wir sehen uns!
            `
          },
          {
            type: 'image',
            src: '/articles/suedtiroler-tag-2022/suedtiroler-tag-2022-preview.png'
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/11-meter-turnier-2022/timg_team_0003.jpg',
    image: '',
    title: 'Rückblick 11-Meter-Turnier 2022',
    description: 'Impressionen zum diesjährigen 11-Meter-Turnier',
    date: '18.06.2022',
    routePath: '11-meter-turnier-2022',
    chapters: [
      {
        header: 'Fischerman\'s Friends holen Sieg',
        paragraphs: [
          {
            type: 'text',
            content: `
              Insgesamt 11 Mannschaften traten auf zwei Spielfeldern gegeneinander an, um sich den begehrten "Andi-Schneider-Gedächtnispokal" zu sichern.
              Den Sieg holte sich die Mannschaft "Fischerman's Friends", die neben der Siegertrophäe als erstes eines der mitgebrachten Presente aussuchen durfte.
            `
          },
          {
            type: 'images',
            sources: [
              '/articles/11-meter-turnier-2022/img_team_0003.jpg',
              '/articles/11-meter-turnier-2022/img_0112.jpg'
            ]
          },
          {
            type: 'images',
            sources: [
              '/articles/11-meter-turnier-2022/img_team_0001.jpg',
              '/articles/11-meter-turnier-2022/img_team_0002.jpg',
              '/articles/11-meter-turnier-2022/img_team_0004.jpg'
            ]
          },
          {
            type: 'images',
            sources: [
              '/articles/11-meter-turnier-2022/img_team_0005.jpg',
              '/articles/11-meter-turnier-2022/img_team_0006.jpg',
              '/articles/11-meter-turnier-2022/img_team_0007.jpg'
            ]
          },
          {
            type: 'images',
            sources: [
              '/articles/11-meter-turnier-2022/img_team_0008.jpg',
              '/articles/11-meter-turnier-2022/img_team_0009.jpg',
              '/articles/11-meter-turnier-2022/img_team_0010.jpg'
            ]
          }
        ]
      },
      {
        header: 'Waschbärboyche halten die Stellung am Zapfhahn',
        paragraphs: [
          {
            type: 'text',
            content: `
              Das Team der Waschbärböyche, das die vergangenen Turniere stehts selbst auf dem Platz vertreten war verzichtete in diesem Jahr auf eine Turnierteilnahme und
              übernahm stattdessen die Bewirtung und versorgte alle Anwesenden rund um die Uhr mit Essen und Getränken.
            `
          },
          {
            type: 'images',
            sources: [
              '/articles/11-meter-turnier-2022/img_team_0011.jpg',
              '/articles/11-meter-turnier-2022/img_0039.jpg'
            ]
          }
        ]
      }
    ],
    galleryPreview: {
      title: '11-Meter-Turnier 2022',
      date: '16.06.2022',
      routePath: '/galerie/events/2022/elfmeter-turnier-2022',
      previewPath: `/galleries/events/2022/elfmeter-turnier-2022/standard/img_0044.jpg`
    }
  },
  {
    thumbnail: '/articles/11-meter-preview.jpg',
    image: '',
    title: '11-Meter-Turnier 2022',
    description: 'Jetzt Anmelden!',
    date: '31.05.2022',
    routePath: '11-meter-turnier-2022-anmeldung',
    chapters: [
      {
        header: 'Nach 2 Jahren Zwangspause...',
        paragraphs: [
          {
            type: 'text',
            content: `
              Nach 2 Jahren Corona-Zwangspause ist es wieder soweit. Am 16.06.2022 (Fronleichnam) findet wieder ein 11-Meter-Turnier in Heselwangen statt.
              Wie in den vergangenen Jahren auch richtet sich diese Veranstaltung hauptsächlich an Mitglieder und Bekannte aus dem Verein.
            `
          },
          {
            type: 'image',
            src: '/articles/11-meter-turnier-flyer.jpeg'
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/f-jugend-und-bambini-spieltag/img_0001.jpg',
    image: '',
    title: 'F-Jugend- und Bambini-Spieltag',
    description: 'Rückblick auf den 3. WFV-Spieltag in Heselwangen',
    date: '29.05.2022',
    routePath: 'f-jugend-und-bambini-spieltag-2022',
    chapters: [
      {
        header: 'Spieltag in Heselwangen',
        paragraphs: [
          {
            type: 'text',
            content: `
              Nach Dotternhausen und Schömberg wurde der 3. WFV-Staffelspieltag am vorletzten Samstag (21.05.22) in Heselwangen ausgerichtet.
              Insgesamt waren 20 Bambini Teams aus 8 Vereinen sowie 11 F-Jugend Teams aus 9 Vereinen zu Gast.
            `
          },
          {
            type: 'images',
            sources: [
              '/articles/f-jugend-und-bambini-spieltag/img_0003.jpg',
              '/articles/f-jugend-und-bambini-spieltag/img_0002.jpg',
              '/articles/f-jugend-und-bambini-spieltag/img_0004.jpg'
            ]
          }
        ]
      },
      {
        header: 'Spaß steht im Vordergrund',
        paragraphs: [
          {
            type: 'text',
            content: `
              Unsere jüngsten Kicker aus den Bambini Mannschaften traten auf 3 Spielfeldern gegeneinander an.
              Zwar ging es bei den Spielen selbstverständlich auch darum Tore zu schießen, gewertet wurden diese allerdings nicht,
              da hier nicht die Leistung, sondern der Spaß im Vordergrund stehen soll.
            `
          },
          {
            type: 'text',
            content: `
              Selbstverständlich steht auch bei der F-Jugend die Freude am Fußballspielen an oberster Stelle, dennoch wurden die Tore der Partien hier notiert.
              Obwohl sämtliche Spiele ohne Schiedsrichter ausgetragen wurden, kam es ausschließlich zu fairen Begegnungen.
              Die Spiele der F-Jugend wurden auf 2 Spielfeldern ausgetragen.
            `
          },
          {
            type: 'images',
            sources: [
              '/articles/f-jugend-und-bambini-spieltag/img_0001.jpg',
              '/articles/f-jugend-und-bambini-spieltag/img_0007.jpg',
              '/articles/f-jugend-und-bambini-spieltag/img_0005.jpg'
            ]
          },
          {
            type: 'text',
            content: `
              Unser besonderer Dank gilt den Helferteams, die sich bei schönstem Wetter um die Verpflegung kümmerten.
            `
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/SVH-Erlebnistag-2022-preview.jpeg',
    image: '',
    title: 'SVH Erlebnistag',
    description: 'Offizieller Saisonabschluss zum letzten Heimspiel.',
    date: '27.05.2022',
    routePath: 'saisonabschluss-2021-2022',
    chapters: [
      {
        header: 'Letzte Heimspiele der Saison 2021/2022',
        paragraphs: [
          {
            type: 'text',
            content: `
              Am kommenden Sonntag bestreiten unsere beiden Herren-Mannschaften ihre letzten Heimspiele der laufenden Saison.
              Los geht es um 13:00 Uhr mit der 2. Mannschaft gegen den FC Hechingen II.
            `
          },
          {
            type: 'text',
            content: `
              Im Anschluss empfängt unsere 1. Mannschaft den Lokalrivalen vom TSV Geislingen.
              In der Liga geht es für beide Mannschaften um nichts mehr - der Klassenerhalt ist gesichert - doch mit einem Sieg
              an diesem und am letzten Spieltag könnte der SVH noch an Geislingen vorbeiziehen und sich in der oberen Tabellenhälfte platzieren.
            `
          }
        ]
      },
      {
        header: 'SVH Erlebnistag',
        paragraphs: [
          {
            type: 'image',
            src: '/articles/SVH-Erlebnistag-2022.jpg'
          },
          {
            type: 'text',
            content: `
              Mit dem letzten Heimspiel feiert der SV Heselwangen nun offiziell den Abschluss der Saison.
              Die Bewirtung übernimmt der Juventus Official Fanclub Zollernalb, der neben Getränken und Roter Wurst auch Pizza anbieten wird.
              Ein weiteres Highlight, auf das sich alle Kids freuen dürfen: Es wird eine Hüpfburg auf dem Nebenplatz geben!
            `
          },
          {
            type: 'text',
            content: `
              Wir hoffen auf bestes Fußballwetter und freuen uns darauf mit unseren Fans gemeinsam den Abschluss einer ordentlichen Saison zu feiern.
            `
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/ehrung-karl-heinz-jetter.jpeg',
    image: '',
    title: 'Karl-Heinz Jetter erhält Bürgermedaille',
    description: 'Unser Ehrenmitglied Karl-Heinz Jetter erhält Bürgermedaille.',
    date: '22.05.2022',
    routePath: 'buergermedaille-karl-heinz-jetter',
    chapters: [
      {
        header: 'Ehrung für besonderes Engagement',
        paragraphs: [
          {
            type: 'text',
            content: `
              Unserem Ehrenmitglied Karl-Heinz-Jetter wurde am vergangenen Donnerstag von Oberbügermeister Helmut Reitemann die Bürgermedaille verliehen.
              Neben 3 weiteren Geehrten erhält Karl-Heinz Jetter diese Auszeichnung für sein besonderes Engagement für die Stadt Balingen und den Stadtteil Heselwangen.
            `
          }
        ]
      }
    ],
    externalLink: {
      name: 'Artikel Schwarzwälder-Bote',
      preview: '/articles/ehrung-karl-heinz-jetter.jpeg',
      destination: 'https://www.schwarzwaelder-bote.de/inhalt.buergermedaille-fuer-vier-balinger-sie-veraendern-das-gesicht-der-welt.013bc44e-61e0-4021-ad89-32bf8b313104.html'
    }
  },
  {
    thumbnail: '/galleries/matches/2021-2022/svh-boll-15-05-22/standard/img_0006.jpg',
    image: '',
    title: 'SVH : TSV Boll (1:4)',
    description: '1. Mannschaft verliert trotz guter Leistung.',
    date: '15.05.2022',
    routePath: 'svh-boll-15-05-22',
    chapters: [
      {
        header: 'TSV Boll mit viel Ballbesitz',
        paragraphs: [
          {
            type: 'text',
            content: `
              Bereits von der 1. Minute an nimmt der TSV Boll das Spiel in die Hand und lässt den Ball in den eigenen Reihen laufen.
              Der SV Heselwangen hingegen agiert hektisch und schafft nach einer Balleroberung nur wenige Ballkontakte, bevor der Ball wieder an die Gäste geht.
              Trotzdem schafft es unsere 1. Mannschaft durch Konter regelmäßig Druck auf den Favoriten aufzubauen, scheitert aber in letzter Konsequenz.
            `
          },
          {
            type: 'text',
            content: `
              Nach etwa 25 Minuten bekommt der gegnerische Stürmer nach einem Standard den Ball im 5-Meter-Raum vor die Füße und steht Frei vor unserem Torhüter.
              Dieser scheitert beim Versuch den Ball zu klären und der TSV Boll erzielt das erste Tor der Partie.
              Nur wenige Minuten später fällt bereits das 0:2. Eine perfekte Flanke der Gäste auf deren Stürmer, der nur noch den Kopf hinhalten muss. Keine Chance für Ersatzkeeper Stephan Reuß.

            `
          }
        ]
      },
      {
        header: 'SVH gibt nicht auf',
        paragraphs: [
          {
            type: 'text',
            content: `
              Unsere Jungs haben zwar weniger Spielanteile, geben sich aber kämpferisch. Kurz vor der Pause gelingt unserem Stürmer Foday Bayo ein klasse Solo.
              Der SVH verkürzt auf 1:2.
            `
          },
          {
            type: 'text',
            content: `
              Die zweite Halbzeit ist nun deutlich zerfahrener. Es ist heiß und die Kräfte schwinden auf beiden Seiten. Beide Mannschaften kommen zu mehreren Torchancen, können diese aber nicht nutzen.
              Erst in der Schlussphase schaffen es die Gäste ihre Führung auszubauen. Am Ende steht es 1:4.
            `
          }
        ]
      },
      {
        header: 'Ergebnis fällt zu hoch aus',
        paragraphs: [
          {
            type: 'text',
            content: `
              Der TSV Boll ist heute als klarer Favorit angereist und konnte diese Rolle spielerisch auch einnehmen. Doch schaut man rein auf die Torchancen wäre hier für den SVH heute mehr drin gewesen.
              Der Sieg für die Gäste ist verdient, täuscht in der Höhe aber über die Leistung unserer Mannschaft hinweg.
            `
          }
        ]
      }
    ],
    galleryPreview: {
      title: 'SVH : TSV Boll (1:4)',
      date: '15.05.2022',
      routePath: '/galerie/spiele/2021/svh-boll-15-05-22',
      previewPath: `/galleries/matches/2021-2022/svh-boll-15-05-22/standard/img_0006.jpg`
    }
  },
  {
    thumbnail: '/galleries/matches/2021-2022/frommern-svh-08-05-22/standard/img_0006.jpg',
    image: '',
    title: 'TSV Frommern : SVH (2:2)',
    description: '1. Mannschaft erlebt 11-Meter-Debakel im Derby gegen Frommern.',
    date: '08.05.2022',
    routePath: 'frommern-svh-08-05-22',
    chapters: [
      {
        header: 'SVH mit viel Geduld',
        paragraphs: [
          {
            type: 'text',
            content: `
              In der ersten Halbzeit steht es lange 0:0. Der SV Heselwangen ist die spielbestimmende Mannschaft und versucht geordnet von hinten heraus Spielzüge aufzubauen, schafft es aber nur selten den Ball
              in der gegnerischen Hälfte zu kontrollieren. Der TSV Frommern widerum antizipiert gut und versucht unsere Mannschaft mit Kontern zu überwinden.
              Erst kurz vor dem Pausenpfiff schafft es Trainer Giuseppe Cracchiolo den Ball mit voller Wucht unter die Latte zu hämmern - 0:1.
            `
          }
        ]
      },
      {
        header: '11-Meter Debakel kostet SVH den Sieg',
        paragraphs: [
          {
            type: 'text',
            content: `
              Nach der Pause kehren die Gastgeber entschlossen auf den Platz zurück und setzen unsere Manschafft direkt unter Druck.
              Torhüter Nico Ast hat Probleme einen Flugball zu kontrollieren und bringt im Nachfassen den gegnerischen Stürmer zu Fall.
              Der TSV Frommern verwandelt den Strafstoß - 1:1.
            `
          },
          {
            type: 'text',
            content: `
              Der SVH lässt sich davon nicht aus der Ruhe bringen und macht weiter wie in Halbzeit 1.
              Doch nach einer knappen viertel Stunde gibt es erneut Strafstoß für die Gastgeber - 2:1.
              Das Spiel wird zunehmend unruhiger und die Spieler beider Mannschaften suchen des öfteren die Diskussion mit der Schiedsrichterin.
            `
          },
          {
            type: 'text',
            content: `
              In der Schlussphase macht der SVH nun ordentlich Druck. Etwa 10 Minuten vor Schluss versucht der Frommerner Keeper einen Flugball zu klären, faustet aber am Ball vorbei.
              Der anschließende Schuss vom SVH wird zunächst per Arm geblockt (nicht strafwürdig). Während die Zuschauer und einige Spieler einen Strafstoß fordern, befördert Jens Fischer den Ball über die Abwehr ins Tor
              und macht die Diskussion über einen Hand-Elfmeter hinfällig. Trotz weiterer guter Chancen kurz vor Schluss schaffen es unsere Jungs nicht ein weiteres Tor nachzulegen. Es bleibt beim 2:2.
            `
          }
        ]
      },
      {
        header: 'Ergebnis in Ordnung',
        paragraphs: [
          {
            type: 'text',
            content: `
              Ein Sieg wäre heute für den SVH aus spielerischer Sicht verdient gewesen.
              Durch die beiden Strafstöße müssen sich unsere Jungs heute aber mit einem Unentschieden zufrieden geben, auch wenn es sich wie eine Niederlage anfühlt.
              Kommende Woche empfängt der SVH den TSV Boll, der aktuell auf dem 2. Tabellenplatz rangiert.
            `
          }
        ]
      }
    ],
    galleryPreview: {
      title: 'TSV Frommern : SVH (2:2)',
      date: '08.05.2022',
      routePath: '/galerie/spiele/2021/frommern-svh-08-05-22',
      previewPath: `/galleries/matches/2021-2022/frommern-svh-08-05-22/standard/img_0006.jpg`
    }
  },
  {
    thumbnail: '/articles/logo-preview.png',
    image: '',
    title: 'Hauptversammlung 2022',
    description: 'Einladung zur Hauptversammlung am Freitag, den 06. Mai 2022',
    date: '02.05.2022',
    routePath: 'einladung-hauptversammlung-2022',
    chapters: [
      {
        header: 'Einladung',
        paragraphs: [
          {
            type: 'text',
            content: `
              Der Vorstand lädt zur jährlichen Hauptversammlung am kommenden Freitag, den 06.05.2022.
              Beginn der Hauptversammlung ist um 20:00 Uhr im Vereinsheim in Heselwangen.
            `
          }
        ]
      },
      {
        header: 'Programm',
        paragraphs: [
          {
            type: 'image',
            src: '/articles/gv-agenda-2022.jpeg'
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/articles/u19-schoemberg-30-04-22-preview.jpg',
    image: '',
    title: 'A-Jugend - SGM Schömberg (5:1)',
    description: 'SVH gewinnt Partie, bei der sich zwei Brüder als Kontrahenten gegenüber stehen.',
    date: '01.05.2022',
    routePath: 'u19-schoemberg-30-04-22',
    chapters: [
      {
        header: 'Zusammenfassung',
        paragraphs: [
          {
            type: 'text',
            content: `
              Beim gestrigen Heimspiel unserer A-Jugend gegen die SGM Schömberg traten die beiden Brüder Tom Sackmann (SVH) und Leon Sackmann (SGM) gegeneinander an.
              Die beiden Spielführer versprachen sich ein faires Spiel zu bestreiten. Dieses versprechen besiegelten sie kurz vor Spielbeginn mit einem symbolischen "Shake-Hands"
              zusammen mit dem Schiedsrichter Jürgen Jesse. Die Partie entschied der SVH souverän mit 5:1 für sich.
            `
          },
          {
            type: 'image',
            src: '/articles/u19-schoemberg-30-04-22.jpg'
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/galleries/matches/2021-2022/svh-killertal-30-04-22/standard/img_0020.jpg',
    image: '',
    title: 'SVH - FC Killertal 04 (1:0)',
    description: 'SVH beendet Negativ-Serie gegen FC Killertal.',
    date: '01.05.2022',
    routePath: 'svh-killertal-30-04-22',
    chapters: [
      {
        header: 'Zusammenfassung',
        paragraphs: [
          {
            type: 'text',
            content: `
              Der SVH zeigt trotz dezimierten Kaders phasenweise ein gutes Spiel und geht nach etwa 20 Minuten
              durch einen Standard (Eigentor des FC. Killertals) mit 1:0 in Führung.
              Unsere 1. Mannschaft erarbeitet sich im weiteren Spielverlauf zahlreiche Torchancen, schafft es aber nicht die Führung auszubauen.
              Stattdessen wird das Spiel immer zerfahrener, was nicht zuletzt an der unklaren Linie des Schiedsrichters liegt.
              Es folgen viele Fouls und Unmut bei den Spielern, wodurch am Ende insgesamt 11 gelbe Karten auf dem Papier stehen.
              Der ein oder andere Spieler hätte auch die zweite gelbe Karte sehen müssen.
              Gegen Ende der zweiten hälfte bekommt der SVH einen Strafstoß, den der Killertaler Keeper leider abwehren kann.
              Danach bekommt der FC Killertal nochmal einen kurzen Aufwind und die Partie droht nochmal spannend zu werden.
              Nach 90+8 Minuten pfeifft der Schiedsrichter das Spiel dann endlich ab und besiegelt diesen Arbeitssieg.
            `
          }
        ]
      },
      {
        header: 'SVH beendet Negativ-Serie',
        paragraphs: [
          {
            type: 'text',
            content: `
              Wer die Duelle gegen den FC Killertal in der vergangenheit verfolgt hat weiß, dass der SVH sich gegen diesen Gegner immerwieder schwer tut.
              Von den letzten 6 Spielen konnte der SVH keines Gewinnen. Gerade einmal 2 Unentschieden und 4 bittere Niederlagen kann der SVH verbuchen.
              Zwar konnte diese Negativ-Serie nun endlich beendet werden, doch mit einem 1:0 können unsere Jungs gegen den aktuell Tabellen-Vorletzten nicht zufrieden sein.
            `
          }
        ]
      }
    ],
    galleryPreview: {
      title: 'SVH : FC Killertal 04 (1:0)',
      date: '30.04.2022',
      routePath: '/galerie/spiele/2021/svh-killertal-30-04-22',
      previewPath: `/galleries/matches/2021-2022/svh-killertal-30-04-22/standard/img_0020.jpg`
    }
  },
  {
    thumbnail: '/articles/kick-in-den-mai-preview.jpg',
    image: '',
    title: 'Kick in den Mai',
    description: 'Am kommenden Samstag steht in Heselwangen ein großer Spieltag auf dem Plan.',
    date: '27.04.2022',
    routePath: 'kick-in-den-mai',
    chapters: [
      {
        header: 'Spiele der Herren vorverlegt',
        paragraphs: [
          {
            type: 'text',
            content: `
              Da der kommende Sonntag auf den 1. Mai fällt, wurden die Spiele der Herren-Mannschaften auf den Samstag vorverlegt.
              Desweiteren finden am Samstag zwei Jugend-Spiele statt. Den Anfang macht die C-Jugend um 11:00 Uhr.
              Im Anschluss folgt die 2. Mannschaft um 13:00 Uhr und die A-Jugend um 15:00 Uhr, bevor unsere 1. Mannschaft etwas ungewohnt um 17:00 Uhr startet.
            `
          },
          {
            type: 'text',
            content: `
              Unsere Bewirtungsteams sind den ganzen Tag für euch im Einsatz und versorgen euch mit Essen und Getränken.
            `
          },
          {
            type: 'image',
            src: '/articles/kick-in-den-mai.jpg'
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/galleries/matches/2021-2022/grosselfingen-svh-18-04-22/standard/img_0012.jpg',
    image: '/galleries/matches/2021-2022/grosselfingen-svh-18-04-22/standard/img_0012.jpg',
    title: 'FC Grosselfingen : SVH (2:0)',
    description: 'SVH gegen Tabellenführer chancenlos.',
    date: '18.04.2022',
    routePath: 'grosselfingen-svh-18-04-22',
    chapters: [
      {
        header: 'Zusammenfassung',
        paragraphs: [
          {
            type: 'text',
            content: `
              Unsere 1. Mannschaft kann heute gegen den FC Grosselfingen nichts ausrichten
              und verliert gegen den aktuellen Tabellenführer knapp aber verdient mit 2:0. Unsere Jungs können dennoch stolz auf ihre Leistung sein.
              Da einige Spieler der 1. Mannschaft heute verletzungsbedingt fehlten, musste kurzfristig auf Spieler aus der 2. Mannschaft zurückgegriffen werden,
              die bereits in der Partie zuvor zum Einsatz kamen.
            `
          }
        ]
      }
    ],
    galleryPreview: {
      title: 'FC Grosselfingen - SVH (2:0)',
      date: '10.04.2022',
      routePath: '/galerie/spiele/2021/grosselfingen-svh-18-04-22',
      previewPath: `/galleries/matches/2021-2022/grosselfingen-svh-18-04-22/standard/img_0012.jpg`
    }
  },
  {
    thumbnail: '/galleries/matches/2021-2022/svh-steinhofen-10-04-22/standard/img_0016.jpg',
    image: '/galleries/matches/2021-2022/svh-steinhofen-10-04-22/standard/img_0016.jpg',
    title: 'SVH : FC Steinhofen (3:2)',
    description: 'SVH siegt knapp aber verdient!',
    date: '10.04.2022',
    routePath: 'svh-steinhofen-10-04-2022',
    chapters: [
      {
        header: 'Zusammenfassung',
        paragraphs: [
          {
            type: 'text',
            content: `
              Zunächst erlebt die 1. Mannschaft ein Déjà-vu zum letzten Heimspiel: 0:1 Rückstand,
              Ausgleich durch einen Standard, 2:1 Führung per Foulelfmeter und der anschließende Ausgleich der Gäste.
              Doch dieses mal hat der SVH noch genug Zeit auf der Uhr, um das Spiel zu drehen und sichert sich den verdienten Sieg mit einem schön herausgespielten Konter.
            `
          }
        ]
      }
    ],
    galleryPreview: {
      title: 'SVH : FC Steinhofen (3:2)',
      date: '10.04.2022',
      routePath: '/galerie/spiele/2021/svh-steinhofen-10-04-22',
      previewPath: `/galleries/matches/2021-2022/svh-steinhofen-10-04-22/standard/img_0016.jpg`
    }
  },
  {
    thumbnail: '/galleries/matches/2021-2022/svh-rosenfeld-27-03-22/standard/img_0001.jpg',
    image: '/galleries/matches/2021-2022/svh-rosenfeld-27-03-22/standard/img_0001.jpg',
    title: 'SVH : SV Rosenfeld (2:2)',
    description: 'Last-Minute-Treffer versaut Heselwangen den Sieg.',
    date: '27.03.2022',
    routePath: 'svh-rosenfeld-27-03-2022',
    chapters: [
      {
        header: 'Zusammenfassung',
        paragraphs: [
          {
            type: 'text',
            content: `
              Obwohl der SV Rosenfeld insgesamt mehr Spielanteile hat, gelingt dem SVH durch zwei Standards eine 2:1 Führung, die unsere Jungs leider nicht bis zum Schluss verteidigen können.
              Ein Sieg wäre heute für unsere 1. Mannschaft zwar glücklich, aber nicht unverdient gewesen. Mit dem Untentschieden können am Ende beide Mannschaften zufrieden sein.
            `
          }
        ]
      }
    ],
    galleryPreview: {
      title: 'SVH : SV Rosenfeld (2:2)',
      date: '27.03.2022',
      routePath: '/galerie/spiele/2021/svh-rosenfeld-27-03-22',
      previewPath: `/galleries/matches/2021-2022/svh-rosenfeld-27-03-22/standard/img_0001.jpg`
    }
  },
  {
    thumbnail: '/articles/kuchenverkauf-preview.jpeg',
    image: '',
    title: 'Doppel-Heimspiel und Kuchenverkauf',
    description: 'SVH veranstaltet am kommenden Doppel-Spieltag einen Kuchenverkauf für Flüchtlinge aus der Ukraine.',
    date: '24.03.2022',
    routePath: 'kuchenverkauf-fuer-fluechtlinge',
    chapters: [
      {
        header: 'Doppel-Heimspiel',
        paragraphs: [
          {
            type: 'text',
            content: `
              Am kommenden Sonntag steht endlich wieder ein Doppel-Heimspiel in Heselwangen auf dem Plan.
              Den Auftakt macht unsere 2. Mannschaft, die in der Kreisliga B auf die SGM SGSL Hörschwag/FC Stetten-SalmendingenII/TV Melchingen II trifft.
              Im Anschluss geht es weiter in der Kreisliga A, wo unsere 1. Mannschaft den SV Rosenfeld empfängt.
            `
          }
        ]
      },
      {
        header: 'Kuchenverkauf für Flüchtlinge',
        paragraphs: [
          {
            type: 'text',
            content: `
              Desweiteren findet bereits ab 13:00 Uhr am Sportplatz ein Kuchenverkauf statt.
              Alle daraus erzielten Einnahmen gehen an das Aufnahmezentrum für Flüchtlinge in Meßstetten.
            `
          },
          {
            type: 'image',
            src: '/articles/kuchenverkauf.jpeg'
          }
        ]
      }
    ]
  },
  {
    thumbnail: '/galleries/trips/2021/ah-wanderung-2021/standard/img_0029.jpg',
    image: '/galleries/trips/2021/ah-wanderung-2021/standard/img_0029.jpg',
    title: 'AH-Wanderung 2021',
    description: 'In diesem Jahr führte die AH-Wanderung in das Tannheimer Tal, wo unsere Kameraden eine Gedenktafel für unseren verstorbenen Freund Hugo Kleiner anbrachten.',
    date: '19.09.2021',
    routePath: 'ah-wanderung-2021',
    chapters: [
      {
        header: 'Anreise | Tag 1',
        paragraphs: [
          {
            type: 'text',
            content: `
              In diesem Jahr führte die AH-Wanderung in das Tannheimer Tal nahe der deutsch-österreichischen Grenze.
              Eine Kleine Gruppe machte sich bereits am frühen Freitag morgen auf den Weg in das Bergdorf Oberjoch, wo bereits unser Kamerad Georg auf sie wartete.
              Dieser hatte wohl keine Lust mit dem Auto anzureisen und nahm lieber das Fahrrad.
            `
          },
          {
            type: 'text',
            content: `
              Gestartet ist er am Sonntag (12. September) und legte in den darauf folgenden 5 Tagen eine Strecke von insgesamt 450KM zurück.
              Sein Weg führte ihn von Balingen über Konstanz und die Schweiz in Richtung Montafon,
              über die Silvretta Hochalpenstraße und das Hahntenjoch bis er schlussendlich in der JochAlpin-Hütte in Oberjoch eintraf.
            `
          },
          {
            type: 'image',
            src: '/galleries/trips/2021/ah-wanderung-2021/bericht/img_0004.jpg'
          },
          {
            type: 'text',
            content: `
              Nach einem gemütlichen Weißwurst-Frühstück, bei dem bereits das ein oder andere Weißbier gekostet wurde,
              machte sich die Gruppe auf den Weg zur ersten Wanderung. Georg, der ursprünglich geplant hatte am Freitag einen Klettersteig zu absolvieren,
              konnte noch rechtzeitig überredet werden mit den anderen doch lieber einen "Hüttensteig" zu absolvieren. Weitere Weißbier folgten ...
            `
          },
          {
            type: 'images',
            sources: [
              '/galleries/trips/2021/ah-wanderung-2021/bericht/img_0001.jpg',
              '/galleries/trips/2021/ah-wanderung-2021/bericht/img_0003.jpg',
              '/galleries/trips/2021/ah-wanderung-2021/bericht/img_0002.jpg'
            ]
          }
        ]
      },
      {
        header: 'Tag 2',
        paragraphs: [
          {
            type: 'text',
            content: `
              Am Samstag Vormittag ging es weiter in das Tannheimer Tal, wo man sich mit der restlichen Wandergruppe traf.
              Zunächst ging es mit der Bergbahn hoch aufs Neunerköpfle, um dort eine Gedenktafel für unseren Vereinskameraden Hugo Kleiner anzubringen,
              der am 20. Juni 2019 im Tannheimer Tal beim Gleitschirmfliegen tödlich verunglückte.
            `
          },
          {
            type: 'images',
            sources: [
              '/galleries/trips/2021/ah-wanderung-2021/standard/img_0027.jpg',
              '/galleries/trips/2021/ah-wanderung-2021/standard/img_0029.jpg'
            ]
          },
          {
            type: 'text',
            content: `
              Im Anschluss folgten unzählige Kilometer und Höhenmeter hinauf zum Nesselwängler Sägewerk zur Nesselwängler Edenalpe,
              wo man nach Speiß und Trank die Nacht verbrachte. Am Sonntag ging es dann auf der anderen Seite hinunter am Haldensee vorbei zurück nach Tannheim,
              ehe man die Heimreise in Richtung Gasthof Ritter in Weilstetten antrat, um dort das anstrengende Wochenende ausklingen zu lassen.
            `
          }
        ]
      }
    ],
    galleryPreview: {
      title: 'AH-Wanderung 2021',
      date: '19.09.2021',
      routePath: '/galerie/ausfluege/2021/ah-wanderung-2021',
      previewPath: `/galleries/trips/2021/ah-wanderung-2021/standard/img_0029.jpg`
    }
  }
];

const getAllNews = (): Article[] => news;
const getLatestNews = (): Article[] => news.slice(0, 4);

export { getAllNews, getLatestNews };
